import $ from 'jquery';
import 'jquery-once';
import '../../vendor/select2/js/select2';
import Autocomplete from './autocomplete';

/**
 * Form element related impl.
 */

const FormElement = (() => {
  'use strict';


  const initAutocomplete = () => {

    // init autocomplete for search input
    Autocomplete.initialize();
  }

  /**
   * initialize dependent elements (radios, checkboxes)
   * @param {*} $context jquery object containing the elements
   */
  const setupDependentElements = ($context) => {

    let $dependants = $('.webform-radios-dependants .form-item, .webform-checkbox-dependants .form-item', $context);
    
    // only if dependants defined
    if ($dependants.length) {
        let $elementWrappers = $('.form-item.form-type-radio, .form-item.form-type-checkbox', $context);

        $elementWrappers.on('change', () => {
    
          // hide and disable all dependant fields first
          $dependants.hide()
            .find('input')
            .each((_, el) => {
              $(el).attr('disabled', 'disabled');
            });
    
          $elementWrappers.each(function () {
            let $element = $(this).children('input');
    
            // display and enable dependent field
            if ($element.is(":checked") && $element.data('dependant')) {
              const dependantId = $element.data('dependant');
              const $dependant = $dependants.find(`#${dependantId}`);
              $dependant.removeAttr('disabled')
                .parent()
                .show();
            }
          });
        });
      }

  };



  const initFormLabels = (context) => {

    const $context = context || $(document);

    $('.form-item', $context).once('formElementLabel').each(function () {
      let $self = $(this),
        $label = $('> label', $self),
        $input;

      if ($label.length) {
        if ($self.is('.form-type-textfield, .form-type-search-api-autocomplete, .form-type-email, .form-type-tel, .form-type-url, .form-type-number')) {
          $input = $('input', $self);
        }
        else if ($self.hasClass('form-type-select')) {
          $input = $('> select', $self);
        }

        if ($input && $input.length) {
          $input.on('focus', () => {
            $label.addClass('visible');
          });

          $input.on('blur', function () {
            if (!$(this).val()) {
              $label.removeClass('visible');
            }
          });
        }
      }

      if ($self.is('.form-type-textfield, .form-type-search-api-autocomplete')) {
        $input = $('input', $self);

        if ($input.val()) {
          $input.addClass('has-value');
        }

        $input.on('input', function () {
          if ($(this).val()) {
            $input.addClass('has-value');
          }
          else {
            $input.removeClass('has-value');
          }
        });
      }
    });
  };

  /**
   * 
   * 
   * initialize select elements with select2 plugin except shs selects
   * for more info read the docs: https://select2.org
   */
  const initSelectElements = (context) => {

    const $context = context || $(document);

    $('select.form-select', $context).not("#partners select.form-select").once('selectToSelect2').each(function () {

      let $this = $(this),
        options = {
          minimumResultsForSearch: '10', // no. of options needed for an active search input.
          placeholder: $this.siblings('label').text().trim()
        };
      
      if ($this.hasClass('modal-select')) {
        options.dropdownParent = $('#drupal-modal').length ? $('#drupal-modal') : $('body');
        if ($('#drupal-modal').length) {
          $('#drupal-modal').addClass('has-select');
        }
      }

      if ($this.prop('multiple')) {
        options.templateResult = multipleSelectformatState;
        if ($this.hasClass('webform-entity-select')) {
          options.closeOnSelect = false;
        }
      }

      setupSelectLabels($this);
      // initialization of shs fields is performed in their specific script
      if (!$this.hasClass('form-select-shs')) {

        $this.select2(options);
      }

    });
  };

  /**
   * create template for multiple select2 element options
   * @param {*} state 
   * @returns 
   */
  const multipleSelectformatState = (state) => {
    var $state = $(
      '<span><span class="checkbox"><span class="tick"></span></span>' + state.text + '</span>'
    );
    return $state;
  };

  const setupSelectLabels = ($select) => {

    let $label = $select.parent('.form-item').find('> label');

    if ($label.length) {
      if ($select.val() && !$.isEmptyObject($(this).val())) {
        $label.addClass('visible');
      }

      // select2 open event handler
      $select.on('select2:opening', function () {
        $label.addClass('visible');
      });
      // select2 close event handler
      $select.on('select2:closing', function () {
        if (!$(this).val() || $.isEmptyObject($(this).val())) {
          $label.removeClass('visible');
        }
        else {
          $(this).parent().addClass('select-has-value');
        }
      });
    }
  };



  const hasErrorMessage = (target) => {
    const inputIsInvalid = target.find('[required]').data('invalid');
    let result = inputIsInvalid ? true : false;

    return result;
  };

  return {
    init: () => {
      initFormLabels();
      initSelectElements();
      initAutocomplete();

    },
    initFormLabels,
    initSelectElements,
    setupDependentElements,
    setupSelectLabels,
    multipleSelectformatState
  }

})($);


export default FormElement;
